import './polyfills';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/fonts/roboto/index.css";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module';

import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import store from './store';
import {QueryClientProvider} from "react-query";
import {queryClient} from "./services/queryClient";
import {CookiesProvider} from "react-cookie";
import * as Sentry from "@sentry/react";

React.icons = icons

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAGMANAGER
}

TagManager.initialize(tagManagerArgs)

Sentry.init({
  dsn: "https://ce7df02fad9a8467460caa6877198b54@o4508251248001024.ingest.us.sentry.io/4508263902150656",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/aluno\.novaconcursos\.com\.br/, /^https:\/\/aluno\.editorasolucao\.com\.br/, /^https:\/\/aluno\.apostilasopcao\.com\.br/],
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
